@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


@layer{
    body {
        font-family: 'Poppins', sans-serif;
    }
    li{
        @apply px-4;
        @apply cursor-pointer;
    }
}